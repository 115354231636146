import { Icon, Stack, StackItem, Tooltip } from '@asgard/ui-library';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
import { formatDateTime, timeAgo } from '@utils/date';
import { useRouter } from 'next/router';
import * as S from './TimeAgo.styled';
import { TimeAgoProps } from './TimeAgo.types';

export const TimeAgo: React.FC<TimeAgoProps> = ({
  dateIsoString,
  withIcon = false,
  prefix = ''
}) => {
  const { locale } = useRouter();

  return (
    <S.Root>
      <Tooltip content={formatDateTime(dateIsoString, locale)}>
        <Stack alignment="center" gap="xs">
          {withIcon && (
            <>
              <Icon icon={faCalendarAlt} size="m" />{' '}
            </>
          )}
          <StackItem>
            {!!prefix && <span>{prefix} </span>}
            {timeAgo(dateIsoString, locale)}
          </StackItem>
        </Stack>
      </Tooltip>
    </S.Root>
  );
};
